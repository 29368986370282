import React from "react"
import { ComingSoon } from "../components/ComingSoon"

const Testimonials = () => {
  return (
    <>
      <ComingSoon />
    </>
  )
}

export default Testimonials
