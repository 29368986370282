import React from "react"
import { Card } from "antd"
import styled from "styled-components"
import { SmileFilled } from "@ant-design/icons"

const StyledCard = styled(Card)`
  justify-content: center;
  text-align: center;
  .ant-card-head {
    font-size: 4vw;
    font-family: "JosefinSans";
    text-align: center;
    @media only screen and (max-width: 800px) {
      font-size: 35px;
    }
  }
`

const StyledHeading = styled.h1`
  font-size: 25px;
  display: flex;
  max-width: 400px;
  justify-content: center;
  margin: auto;
`

const StyledIcon = styled(SmileFilled)`
  margin-top: auto;
  margin-bottom: auto;
`

export const ComingSoon = props => {
  return (
    <>
      <StyledCard title={props.title}>
        <StyledHeading>
          <strong>
            COMING S<StyledIcon />
            <StyledIcon />
            N!
          </strong>
        </StyledHeading>
      </StyledCard>
    </>
  )
}
